// Step 1: Import React
import * as React from "react";
import Layout from "../components/layout";

import { GatsbyImage } from "gatsby-plugin-image";
import { Link } from "gatsby";
import { graphql } from "gatsby";

// Step 2: Define your component
const CoursePage = ({ pageContext, data }) => {
  // console.log(pageContext)

  const node = pageContext.node.node;
  const prev = pageContext.node.previous;
  const next = pageContext.node.next;
  const media = data.allFile.edges;
  // console.log(node.content)

  console.log(media);
  // const prev = data.
  return (
    <Layout pageTitle="Course Page">
      <section className="course-intro">
        <div className="course-intro__coverimg">
          <GatsbyImage
            image={
              node.featuredImage.node.localFile.childImageSharp.gatsbyImageData
            }
            style={{ height: "100%", width: "100%" }}
            imgStyle={{ objectFit: "cover" }}
            alt="image of course"
          />

          <div className="course-intro__bgoverlay"></div>
        </div>
        <div className="course-intro__text">
          <h4 className="course-intro__date">{node.date}</h4>
          <h1 className="heading-lg course-intro__heading">{node.title}</h1>

          {node.courseAcf.hasCourse ? (
            <a
              href={`https://course.lilianportfolio.me/${node.slug}`}
              rel="noreferrer"
              target="_blank"
              className="btn btn-pink"
            >
              Experience The Course
            </a>
          ) : (
            ""
          )}
        </div>
        <div className="course-intro__nav">
          {prev !== null ? (
            <Link
              to={`/portfolio/${prev.slug}`}
              className="course-intro__link previous "
            >
              Previous
            </Link>
          ) : (
            <Link
              to="/portfolio"
              state={{ category: "default" }}
              className="course-intro__link "
            >
              Portfolio
            </Link>
          )}

          {next !== null ? (
            <Link
              to={`/portfolio/${next.slug}`}
              className="course-intro__link next "
            >
              Next
            </Link>
          ) : (
            <Link
              to="/portfolio"
              state={{ category: "default" }}
              className="course-intro__link "
            >
              Portfolio
            </Link>
          )}
        </div>
      </section>

      <section className="course-content">
        {node.content !== null ? (
          <div className="course-content__brief">
            <div
              className="course-content__content"
              dangerouslySetInnerHTML={{ __html: node.content }}
            />
          </div>
        ) : (
          ""
        )}

        <div className="course-content__section--1">
          {node.courseAcf.audience !== null ? (
            <div className="course-content__audience">
              <span className="bold">Audience:</span> {node.courseAcf.audience}{" "}
            </div>
          ) : (
            ""
          )}
          {node.courseAcf.responsibilities !== null ? (
            <div className="course-content__respo">
              <span className="bold">Responsibilities:</span>{" "}
              {node.courseAcf.responsibilities}{" "}
            </div>
          ) : (
            ""
          )}
          {node.courseAcf.tools !== null ? (
            <div className="course-content__tools">
              <span className="bold">Tools:</span> {node.courseAcf.tools}{" "}
            </div>
          ) : (
            ""
          )}
        </div>

        {node.courseAcf.hasCourse ? (
          <a
            href={`https://course.lilianportfolio.me/${node.slug}`}
            rel="noreferrer"
            target="_blank"
            className="btn course-content__btn"
          >
            Experience The Course
          </a>
        ) : (
          ""
        )}

        <div className="course-content__section--2">
          {node.courseAcf.probSol !== null ? (
            <div className="course-content__probsol">
              <h1 className="course-content__heading">Problem & Solution</h1>
              <div
                className="course-content__content"
                dangerouslySetInnerHTML={{ __html: node.courseAcf.probSol }}
              />
            </div>
          ) : (
            ""
          )}
          {node.courseAcf.process !== null ? (
            <div className="course-content__process">
              <h1 className="course-content__heading">Process</h1>
              <div
                className="course-content__content"
                dangerouslySetInnerHTML={{ __html: node.courseAcf.process }}
              />
            </div>
          ) : (
            ""
          )}
          {node.courseAcf.feature !== null ? (
            <div className="course-content__feature">
              <h1 className="course-content__heading">Features</h1>
              <div
                className="course-content__content"
                dangerouslySetInnerHTML={{ __html: node.courseAcf.feature }}
              />
            </div>
          ) : (
            ""
          )}
          {node.courseAcf.reflection !== null ? (
            <div className="course-content__reflection">
              <h1 className="course-content__heading">Reflection</h1>
              <div
                className="course-content__content"
                dangerouslySetInnerHTML={{ __html: node.courseAcf.reflection }}
              />
            </div>
          ) : (
            ""
          )}
          {media.length > 0 ? (
            <div className="course-content__gallery">
              <h1 className="course-content__heading">Gallery</h1>
              {media.map((item) => (
                <GatsbyImage
                  key={item.node.id}
                  className="course-content__media-item"
                  image={item.node.childImageSharp.gatsbyImageData}
                  alt="image of course"
                />
              ))}
            </div>
          ) : (
            ""
          )}
        </div>
      </section>
      {node.courseAcf.hasCourse ? (
        <div className="course-content__section--3">
          <h2 className="lg-text white-text med">
            Don't take my word for it! You can see it for yourself!
          </h2>
          <a
            href={`https://course.lilianportfolio.me/${node.slug}`}
            rel="noreferrer"
            target="_blank"
            className="btn"
          >
            Experience The Course
          </a>
        </div>
      ) : (
        ""
      )}
    </Layout>
  );
};

export const postQuery = graphql`
  query ($slug: String) {
    allFile(
      filter: {
        sourceInstanceName: { eq: "courseimg" }
        relativeDirectory: { eq: $slug }
      }
    ) {
      edges {
        node {
          id
          relativeDirectory
          childImageSharp {
            gatsbyImageData
          }
        }
      }
    }
  }
`;

export default CoursePage;
